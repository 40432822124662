var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row mr-3" }, [
    _c(
      "div",
      { staticClass: "col d-flex justify-content-end" },
      [
        _vm.ods.canceled_at == null &&
        _vm.canAny([
          "override_rest_ods",
          "override_gs_ods",
          "override_threedops_ods",
          "override_host_ods"
        ]) &&
        ["confirmed", "approved"].includes(_vm.$ods.status) &&
        _vm.ods.done != 1
          ? _c(
              "b-button",
              {
                staticClass: "btn-label",
                attrs: { id: "toggle-btn", variant: "danger" },
                on: {
                  click: function($event) {
                    return _vm.$refs["modal-center"].toggle("#toggle-btn")
                  }
                }
              },
              [
                _c("i", { staticClass: "fas fa-times-circle  label-icon" }),
                _vm._v(" Annuler cet ODS ")
              ]
            )
          : _vm._e(),
        _vm.ods.canceled_at != null
          ? _c(
              "div",
              {
                staticClass: "d-flex align-items-center justify-content-between"
              },
              [
                _c("h4", [
                  _c(
                    "span",
                    {
                      staticClass: "badge badge-danger",
                      staticStyle: { cursor: "pointer" },
                      attrs: { id: "toggle-info" },
                      on: {
                        click: function($event) {
                          return _vm.$refs["modal-info"].toggle("#toggle-info")
                        }
                      }
                    },
                    [_vm._v(" Annulé")]
                  )
                ])
              ]
            )
          : _vm._e(),
        _c(
          "b-modal",
          {
            ref: "modal-center",
            attrs: {
              id: "modal-center",
              centered: "",
              title: "Valider Annulation " + _vm.ods.ref,
              "title-class": "font-18",
              size: "lg",
              "hide-footer": ""
            }
          },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v("Motif d'Annulation")
                ]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.cancelation,
                      expression: "cancelation"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { cols: "10", rows: "3" },
                  domProps: { value: _vm.cancelation },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.cancelation = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "row mt-2" }, [
              _c(
                "div",
                { staticClass: "col text-right" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-label",
                      attrs: { variant: "info" },
                      on: { click: _vm.cancel }
                    },
                    [
                      _c("i", { staticClass: "fas fa-check  label-icon" }),
                      _vm._v(" Confirmer l'Annulation ")
                    ]
                  )
                ],
                1
              )
            ])
          ]
        ),
        _c(
          "b-modal",
          {
            ref: "modal-info",
            attrs: {
              id: "modal-center",
              centered: "",
              title: "Info Annulation " + _vm.ods.ref,
              "title-class": "font-18",
              size: "lg",
              "hide-footer": ""
            }
          },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("table", { staticClass: "table" }, [
                  _c("tr", [
                    _c("th", [_vm._v("Annulé Par: ")]),
                    _vm.ods.cancel_by != null
                      ? _c("td", [
                          _c("span", { staticClass: "badge badge-info" }, [
                            _vm._v(
                              _vm._s(_vm.ods.cancel_by.firstname) +
                                " " +
                                _vm._s(_vm.ods.cancel_by.lastname)
                            )
                          ])
                        ])
                      : _vm._e()
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Date et heure Annulation: ")]),
                    _c("td", [_vm._v(" " + _vm._s(_vm.ods.canceled_at) + " ")])
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Motif Annulation: ")]),
                    _c("td", [
                      _vm._v(" " + _vm._s(_vm.ods.canceled_reason) + " ")
                    ])
                  ])
                ])
              ])
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }